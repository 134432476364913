/* Height 100% must be set down to root, or else our app will not fill the screen
 * See https://stackoverflow.com/a/54918412 */
html {
  background-color: white;
  height: 100%;
  margin: 0;
  overflow-y: hidden;
}

body {
  background-color: white;
  height: 100%;
  margin: 0;
  overflow-y: hidden;
}

/* Set overflow for our root to scroll, so that when content exceed viewport, the browser address bar on mobile
 * will not hide. Else, the flex for filler container is not working properly when switching route of different
 * height.
 * See https://stackoverflow.com/a/33953987 */
#root {
  background-color: white;
  height: 100%;
  margin: 0;
  overflow-y: scroll;
}